import { axiosInstance } from "..";

const interiorDesignSubCategoriesList = async (querry) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .get(`internal/Interiordesign/categories?subCategory=1`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default interiorDesignSubCategoriesList;
